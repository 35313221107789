import React from "react";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import PlotAPI from "../../plotAPI";
import Modal from "../UI/Modal";
import TeamAvatar from "./teamAvatar";

export default function AcceptInviteModal(props: any) {
    const [teamName, setTeamName] = React.useState("");
    const [teamId, setTeamId] = React.useState(null);
    const [visible, setVisible] = React.useState(true);
    const context = React.useContext(GlobalStoreContext);

    React.useEffect(() => {
        if (props.teamName && props.teamId) {
            setTeamName(props.teamName);
            setTeamId(props.teamId);
        }
    }, []);

    function hideModal() {
        setVisible(false);
    }

    function onRevokeClick() {
        PlotAPI.revokeInviteToTeam(context.userInfo.id, context.currentViewedTeamInfo.subdomain, teamId).then(
            (response) => {
                if (response && response.success) {
                    Helper.setCookie("invited_team", "", -1);
                    Helper.setCookie("invited_team_id", "", -1);
                    hideModal();
                    location.reload();
                }
            }
        );
    }

    function onAcceptClick() {
        PlotAPI.acceptInviteToTeam(context.currentViewedTeamInfo.subdomain, teamId).then((response) => {
            if (response && response.success) {
                Helper.setCookie("invited_team", "", -1);
                Helper.setCookie("invited_team_id", "", -1);
                hideModal();

                location.reload();
            }
        });
    }

    return (
        <>
            <Modal title="Team Invitation" open={visible} onCancel={hideModal} footer={null}>
                <div className="mb-6 flex flex-row items-center justify-center">
                    You have been invited to join
                    <div className="mx-2">
                        <TeamAvatar team={props.team} />
                    </div>
                    {teamName}
                </div>

                <div>
                    <button
                        type="button"
                        className="plot-button secondary-button w-1/2 inline-block!"
                        onClick={onRevokeClick}
                    >
                        Decline
                    </button>
                    <button
                        type="button"
                        className="plot-button primary-button w-1/2 inline-block! rounded-xs!"
                        onClick={onAcceptClick}
                    >
                        Accept
                    </button>
                </div>
            </Modal>
        </>
    );
}
