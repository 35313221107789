import { AccordionDetails, AccordionSummary } from "@mui/material";
import { Checkbox, Col, Row } from "antd";
import React from "react";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import IntegrationIcon from "./integrationIcon";
import IntegrationName from "./integrationName";

interface IntegrationVendorsProps {
    integrationVendors: any;
    setIntegrationVendors: (newVendors: any) => void;
    updateMembers: () => void;
}

const AddIntegrationVendorsToProject: React.FC<IntegrationVendorsProps> = ({
    integrationVendors,
    setIntegrationVendors,
    updateMembers,
}) => {
    const context = React.useContext(GlobalStoreContext);

    const shouldCreateVendorTeam = (vendor) => {
        return vendor.checked || (vendor.contacts_checked && vendor.contacts_checked.length > 0);
    };

    const onIntegrationCreate = () => {
        const vendorsAndContactsToCreate = integrationVendors
            .filter((vendor) => shouldCreateVendorTeam(vendor))
            .map((vendor) => {
                const contactsSelected = vendor.contacts_checked || [];
                const filteredContacts = vendor.contacts.filter((contact) => contactsSelected.includes(contact.id));
                return {
                    ...vendor,
                    contacts: filteredContacts,
                };
            });

        Helper.plotFetch(`/projects/${context.currentProjectInfo.unique_token}/integrations_import_members`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(vendorsAndContactsToCreate),
        }).then(() => {
            updateMembers();
        });
    };

    return (
        <>
            {context.currentProjectInfo.integration_source_project_id &&
                context.currentProjectInfo.integration_import_members && (
                    <>
                        <AccordionSummary>
                            <span>Add Contractors from</span>
                            <IntegrationIcon
                                project={context.currentProjectInfo}
                                className="w-[24px]! h-[24px]! mx-2"
                                activated={true}
                            />
                            <IntegrationName project={context.currentProjectInfo} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="mb-2!">
                                <p>
                                    Select the Companies that you would like to be created, then select the contacts for
                                    each company you would like to be invited to them.
                                </p>
                            </div>
                            <div className="mb-2! p-2 border border-solid border-black rounded-lg">
                                {integrationVendors.map((vendor, vendor_index) => (
                                    <div key={vendor_index}>
                                        <Checkbox
                                            indeterminate={
                                                vendor.contacts_checked &&
                                                vendor.contacts_checked.length > 0 &&
                                                vendor.contacts_checked.length < vendor.contacts.length
                                            }
                                            checked={vendor.checked}
                                            onChange={(e) => {
                                                const new_vendors = JSON.parse(JSON.stringify(integrationVendors));

                                                new_vendors.forEach((vendor, v_index) => {
                                                    if (v_index == vendor_index) {
                                                        vendor.checked = e.target.checked;

                                                        if (!vendor.contacts_checked) {
                                                            vendor.contacts_checked = [];
                                                        }

                                                        if (e.target.checked) {
                                                            vendor.contacts_checked = new_vendors[v_index].contacts.map(
                                                                (contact) => contact.id
                                                            );
                                                        } else {
                                                            vendor.contacts_checked = [];
                                                        }
                                                    }
                                                });

                                                setIntegrationVendors(new_vendors);
                                            }}
                                        >
                                            {vendor.name}
                                        </Checkbox>
                                        <Checkbox.Group
                                            style={{ width: "100%" }}
                                            onChange={(e) => {
                                                const new_vendors = JSON.parse(JSON.stringify(integrationVendors));

                                                new_vendors.forEach((vendor, v_index) => {
                                                    if (v_index == vendor_index) {
                                                        vendor.contacts_checked = e;

                                                        if (vendor.contacts_checked.length == vendor.contacts.length) {
                                                            vendor.checked = true;
                                                        } else {
                                                            vendor.checked = false;
                                                        }
                                                    }
                                                });

                                                setIntegrationVendors(new_vendors);
                                            }}
                                            value={vendor.contacts_checked}
                                        >
                                            <Row className="pl-6">
                                                {vendor.contacts.map((contact, contact_index) => (
                                                    <Col span={24} key={contact_index}>
                                                        <Checkbox value={contact.id}>
                                                            {contact.first_name} {contact.last_name}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                ))}
                            </div>
                            <button
                                type="button"
                                className="plot-button primary-button w-full"
                                onClick={() => {
                                    onIntegrationCreate();
                                }}
                            >
                                Create
                            </button>
                        </AccordionDetails>
                    </>
                )}
        </>
    );
};

export default AddIntegrationVendorsToProject;
