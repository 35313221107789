import { faCheckCircle, faPlus, faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@mui/material";
import { Divider, notification, Radio, Select } from "antd";
import React, { useEffect } from "react";
import { globalAppConfig } from "../../config";
import GlobalStoreContext from "../../context/GlobalStoreContext";
import Helper from "../../Helper";
import PlotAPI from "../../plotAPI";
import AddTeamGeocoder from "../Search/AddTeamGeocoder";
import TeamAvatar from "../Team/teamAvatar";
import Modal from "../UI/Modal";
import Tooltip from "../UI/Tooltip";
import AddIntegrationVendorsToProject from "./addIntegrationVendorsToProject";
import IntegrationIcon from "./integrationIcon";
import IntegrationName from "./integrationName";

interface AddTeamToProjectModalProps {
    callBackFunction?: (val: any) => void; // Accepts the team id of the team to be added to the project
    controlledOpen?: "vendor" | "member" | null;
    closeControlledOpen?: () => void;
}

const AddTeamToProjectModal: React.FC<AddTeamToProjectModalProps> = ({
    callBackFunction,
    controlledOpen,
    closeControlledOpen,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [teams, setTeams] = React.useState<any[]>([]);
    const [integrationVendors, setIntegrationVendors] = React.useState<any[]>([]);
    const [selectedTeam, setSelectedTeam] = React.useState<any>(null);
    const [searchContainerOpen, setSearchContainerOpen] = React.useState<boolean>(false);
    const [selectedCreateMode, setSelectedCreateMode] = React.useState<"MANUAL" | "INTEGRATION">("MANUAL");
    const [newTeamFlag, setNewTeamFlag] = React.useState<boolean>(false);
    const [searchResults, setSearchResults] = React.useState<any[]>([]);
    const [urlParams] = React.useState(new URLSearchParams(window.location.search));
    const [addUserToTeam, setAddUserToTeam] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [vendorFlag, setVendorFlag] = React.useState<boolean>(false);
    const context = React.useContext(GlobalStoreContext);

    useEffect(() => {
        if (controlledOpen || context.addTeamToProjectModal) {
            setOpen(true);
        }
    }, [controlledOpen, context.addTeamToProjectModal]);

    useEffect(() => {
        if (open) {
            getTeams();
            getIntegrationVendors();
        }
    }, [open]);

    useEffect(() => {
        if (controlledOpen == "vendor" || context.addTeamToProjectModal == "vendor") {
            setVendorFlag(true);
        } else {
            setVendorFlag(false);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            checkUrlParams();
        }
    }, [teams, open]);

    const checkUrlParams = () => {
        if (urlParams.get("add_team")) {
            onSearch(urlParams.get("add_team"), true);
        }
        if (urlParams.get("invite_contact")) {
            setAddUserToTeam(true);
        }
        setLoading(false);
    };

    const getTeams = () => {
        const url = "/teams";
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Failed to get teams");
            })
            .then((response) => {
                setTeams(response);
            })
            .catch(() => {});
    };

    const getIntegrationVendors = () => {
        PlotAPI.getIntegrationContactsByVendor(
            context.currentProjectInfo.claimed_by_team_subdomain || "projects",
            context.currentProjectInfo.unique_token
        ).then((response) => {
            if (response) {
                setIntegrationVendors(response);
            }
        });
    };

    const onSearch = (val, urlParam: boolean = false) => {
        setSearchValue(val);

        if (urlParam) {
            setSearchContainerOpen(true);
        }
        let search_results = new Array<any>();
        if (val.length >= 3) {
            search_results = teams.filter((team) => {
                const team_name = team.team_name.toLowerCase();
                setSearchContainerOpen(true);
                return team_name.includes(val.toLowerCase());
            });

            search_results.forEach((team, index) => {
                team.already_on_project = false;
                if (context.currentProjectInfo.owning_team_id == team.id) {
                    team.already_on_project = true;
                }
                if (context.currentProjectInfo.teams?.find((t) => t.id == team.id)) {
                    team.already_on_project = true;
                }
                if (context.vendorsForProject(context.currentProjectInfo.unique_token)?.find((t) => t.id == team.id)) {
                    team.already_on_project = true;
                }
            });

            search_results = search_results.slice(0, 10);

            setSearchResults(search_results);
        }

        // Automatically open the create team dialog if coming from url param
        if (urlParam && search_results.length == 1 && search_results[0].id == "NEW") {
            setSelectedTeam(search_results[0]);
            setNewTeamFlag(true);
        } else {
            setSearchResults(search_results);
        }
    };

    const inviteUserToTeam = async (response) => {
        return new Promise((resolve, reject) => {
            if (addUserToTeam) {
                const contactInfo = urlParams.get("invite_contact");
                if (contactInfo) {
                    return resolve(
                        PlotAPI.inviteToTeam(
                            [{ contact_info: contactInfo }],
                            response.subdomain,
                            context.currentProjectInfo.unique_token
                        )
                    );
                } else {
                    notification.error({
                        message: "Failed to Invite User",
                        description: "The email or phone number provided is invalid",
                        duration: 5,
                    });
                    return reject();
                }
            } else {
                return resolve(null);
            }
        });
    };

    const integrationSyncTitleContent = () => {
        return (
            <div className="h-full flex items-center overflow-clip">
                <IntegrationIcon project={context.currentProjectInfo} className="h-[50%] w-auto" activated={true} />
                <div className="whitespace-nowrap text-clip overflow-clip inline ml-1">Add from Submittals</div>
            </div>
        );
    };

    const integrationSyncTitle = () => {
        return context.currentProjectInfo ? (
            integrationSyncTitleContent()
        ) : (
            <Tooltip
                title={
                    <IntegrationName project={context.currentProjectInfo} /> +
                    " Materials must be imported on the Jobsite page"
                }
            >
                {integrationSyncTitleContent()}
            </Tooltip>
        );
    };

    const createTeamAndAddToProject = async (team) => {
        if (!team.team_name) {
            notification.error({
                message: "Failed to Create Company",
                description: "Company name is required",
                duration: globalAppConfig.NOTIFICATION_DURATION,
            });
            return;
        }
        // At one point we required an address to create a team, but we don't anymore
        // if (!team.address_1) {
        //     notification.error({
        //         message: "Failed to Create Company",
        //         description: "Company address is required",
        //         duration: globalAppConfig.NOTIFICATION_DURATION,
        //     });
        //     return;
        // }
        PlotAPI.postTeam(team)
            .then((response) => {
                const teamInfoResponse = response;
                return inviteTeamToProject(teamInfoResponse);
            })
            .then((response) => {
                context.reloadCurrentTeamsProjects();
            });
    };

    const inviteTeamToProject = async (team) => {
        PlotAPI.addTeam(context.currentProjectInfo.unique_token, team.id, vendorFlag ? "vendor" : "member").then(
            (response) => {
                if (response) {
                    window.history.replaceState({}, "", window.location.pathname);
                    if (addUserToTeam) {
                        inviteUserToTeam(response);
                        setAddUserToTeam(false);
                    }
                    if (callBackFunction) {
                        callBackFunction(team.id);
                    }
                    context.refreshTeamsByProject();
                    notification.success({
                        message: "Company Added",
                        description: `${team.team_name} has been added to the jobsite`,
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                    setSearchContainerOpen(false);
                    setSearchValue("");
                    setNewTeamFlag(false);
                    setSelectedTeam(null);
                    if (callBackFunction) {
                        closeModal();
                    }
                }
            }
        );
    };

    const clearSearchParams = () => {
        window.history.replaceState(null, "", window.location.pathname);
    };

    const closeModal = () => {
        setSearchContainerOpen(false);
        setSearchValue("");
        setNewTeamFlag(false);
        setAddUserToTeam(false);
        setSelectedTeam(null);
        clearSearchParams();
        context.setAddTeamToProjectModal(null);
        context.refreshTeamsByProject();
        context.refreshVendorsByProject();
        if (closeControlledOpen) {
            closeControlledOpen();
        }
        setOpen(false);
    };

    return (
        <>
            <Modal
                title={vendorFlag ? "Add Vendor to Jobsite" : "Add Trade Contractor to Jobsite"}
                open={open ? true : false}
                onClose={() => {
                    closeModal();
                }}
                onCancel={() => {
                    closeModal();
                }}
                destroyOnClose={true}
            >
                <Radio.Group
                    className="w-full flex! items-center"
                    value={selectedCreateMode}
                    onChange={(e) => setSelectedCreateMode(e.target.value as "MANUAL" | "INTEGRATION")}
                >
                    <Radio.Button className={"w-1/2"} value={"MANUAL"}>
                        Add Manually
                    </Radio.Button>
                    <Radio.Button className={"w-1/2"} value={"INTEGRATION"} disabled={!context.currentProjectInfo}>
                        <div className="flex flex-row items-center">
                            Import from
                            <IntegrationIcon
                                project={context.currentProjectInfo}
                                className="w-[24px]! h-[24px]! mx-2"
                                activated={true}
                            />
                            <IntegrationName project={context.currentProjectInfo} />
                        </div>
                    </Radio.Button>
                </Radio.Group>

                <div className="mt-4" />

                {selectedCreateMode == "MANUAL" && (
                    <>
                        <p className="claimJoinLabel">
                            Use the field below to search for an existing {vendorFlag ? "vendor" : "company"} or create
                            a new {vendorFlag ? "vendor" : "company"} to add to your project
                        </p>
                        <Select
                            open={searchContainerOpen}
                            showSearch
                            allowClear
                            onClear={() => {
                                setSearchValue("");
                                setNewTeamFlag(false);
                                setAddUserToTeam(false);
                                setSearchContainerOpen(false);
                                setSelectedTeam(null);
                            }}
                            value={searchValue}
                            style={{ color: "black" }}
                            placeholder="Enter a company name..."
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={onSearch}
                            onSelect={(value, option) => {
                                setSelectedTeam(option);
                                setSearchContainerOpen(false);
                            }}
                            notFoundContent={
                                searchValue.length >= 3
                                    ? `No companies found for ${searchValue}. Click the button below to create ${searchValue} on PLOT.`
                                    : null
                            }
                            options={(searchResults || []).map((d) => ({
                                value: d.id,
                                label: d.team_name,
                                already_on_project: d.already_on_project,
                                team: d,
                            }))}
                            className="w-full"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: "8px 0" }} />

                                    <button
                                        className={"plot-button primary-button  block-button"}
                                        onClick={() => {
                                            setSelectedTeam({ id: "NEW", team_name: searchValue });
                                            setNewTeamFlag(true);
                                            setSearchContainerOpen(false);
                                        }}
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faPlus} />
                                        Create New {vendorFlag ? "Vendor" : "Trade"} {searchValue}
                                    </button>
                                </>
                            )}
                            optionRender={(option) => {
                                return (
                                    <div
                                        className={Helper.classNames(
                                            "flex flex-row items-center justify-between p-2 rounded-xs"
                                        )}
                                    >
                                        <div className="flex flex-row items-center gap-2">
                                            <TeamAvatar team={option.data.team} size={20} />
                                            <div>{option.label}</div>
                                        </div>
                                        {option.data.already_on_project && (
                                            <div className="flex flex-row items-center gap-2">
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                Already on Jobsite
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                        {newTeamFlag && (
                            <div className="mt-2 mb-2 p-5 shadow-lg">
                                <p className="font-[Oswald] text-lg">Create New Company: {selectedTeam.team_name}</p>
                                <p className="font-[Oswald] mt-1!">Confirm New Company Name</p>
                                <div className="px-2">
                                    <input
                                        value={selectedTeam.team_name}
                                        onChange={(e) => {
                                            setSelectedTeam({ ...selectedTeam, team_name: e.target.value });
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="px-2 mt-4">
                                    <AddTeamGeocoder
                                        updateAddress={(result) => {
                                            setSelectedTeam({
                                                ...selectedTeam,
                                                address_1: result.team.address_1,
                                                address_2: result.team.address_2,
                                                city: result.team.city,
                                                state: result.team.state,
                                                zip_code: result.team.zip_code,
                                                lat: result.team.lat,
                                                lon: result.team.lon,
                                            });
                                        }}
                                        placeholder={`Search for ${selectedTeam.team_name}'s address`}
                                    />
                                </div>

                                {urlParams.get("invite_contact") && (
                                    <div className="flex flex-row items-center justify-start gap-4 p-4">
                                        <Checkbox
                                            checked={addUserToTeam}
                                            onClick={() => setAddUserToTeam((prevState) => !prevState)}
                                        />
                                        <div>
                                            Add <span className="font-semibold">{urlParams.get("invite_contact")}</span>{" "}
                                            user to <span className="font-semibold">{selectedTeam.team_name}</span>?
                                        </div>
                                    </div>
                                )}

                                <button
                                    className="plot-button primary-button mt-4 block-button"
                                    disabled={loading}
                                    onClick={() => createTeamAndAddToProject(selectedTeam)}
                                >
                                    Create Company
                                </button>
                            </div>
                        )}
                        {selectedTeam && !selectedTeam.already_on_project && !newTeamFlag && (
                            <button
                                className="plot-button primary-button block-button mt-4"
                                onClick={() => inviteTeamToProject(selectedTeam.team)}
                            >
                                <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                                Add {selectedTeam.label} to {context.currentProjectInfo.project_name}
                            </button>
                        )}
                    </>
                )}

                {selectedCreateMode == "INTEGRATION" && (
                    <AddIntegrationVendorsToProject
                        integrationVendors={integrationVendors}
                        setIntegrationVendors={setIntegrationVendors}
                        updateMembers={context.refreshTeamsByProject}
                    />
                )}
            </Modal>
        </>
    );
};

export default AddTeamToProjectModal;
